//アコーディオン
const acco = document.querySelectorAll(".acco-menu");
function accordion() {
  const content = this.nextElementSibling;
  this.classList.toggle("is-active");
  content.classList.toggle("is-acco");
}
for (let i = 0; i < acco.length; i++) {
  acco[i].addEventListener("click", accordion);
}
