function toggleNav() {
  const body = document.body;
  const hamburger = document.getElementById('navBtn');
  const navitem = document.getElementById('navList');
  hamburger.addEventListener('click', function() {
    body.classList.toggle('nav-open');
  });
  navitem.addEventListener('click', function() {
    body.classList.remove('nav-open');
  });
}
toggleNav();
