$(function(){
const tipsBtn = document.querySelectorAll(".tips-btn");
function tips() {
  const content = this.nextElementSibling;
  this.classList.toggle("is-active");
  content.classList.toggle("is-tips");
}
for (let i = 0; i < tipsBtn.length; i++) {
  tipsBtn[i].addEventListener("click", tips);
}
});
